import React, { useEffect, useState } from 'react'

import NavBar from '../components/navbar/NavBar'
import StarParalax from '../components/starparalax/StarParalax'
import Footer from '../components/footer/Footer'
import ScrollTop from '../components/scrolltop/ScrollTop'
import SearchBar from '../components/searchbar/SearchBar'
import ArtistAvatar from '../components/artistavatar/ArtistAvatar'

import Slider from "react-slick";

import ExclusiveDrops from '../components/exclusivedrops/ExclusiveDrops'
import HotAuction from '../components/hotauction/HotAuction'
import FilterButton from '../components/filterbutton/FilterButton'
import ExploreImage from '../components/exploreimage/ExploreImage'
import BuyImage from '../assets/images/buy.png'
import MainLogo from '../assets/images/main-logo.png'

const Home = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0x241b8e70e25495bb347b3e267ad2e27dcc3b2506')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <>
            <div className="landing-page-main">
                <NavBar />
                <div className="main-hero">
                    <div className="container">
                        <div className="main-logo">
                            <img src={MainLogo} alt="" />
                        </div>
                        <div className="col-md-8 col-md-offset-2">
                            <div className="youtube-video">
                                <h2>UPDATING SOON</h2>
                                <div className="main-video">
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/aMsHhAUIcxQ?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
                {/* <ScrollTop /> */}
            </div>

        </>
    )
}

export default Home
